














































































import defaultComponent from "../utils/defaultComponent";
import propertyGetter from "../utils/propertyGetter";
import Vue from "vue";
import JsonViewer from "vue-json-viewer";

export default defaultComponent.apply({
  components: {
    JsonViewer,
  },
  props: ["item", "value"],
  data() {
    return {};
  },
  computed: {
    tooltip() {
      if (!this.options.tooltip && !this.options.textMaxWidth) {
        return "";
      }
      if (typeof this.options.tooltip !== "function") {
        return this.finalValue;
      }
      return this.options.tooltip(this.finalValue, this.item);
    },
    tooltipAttrs() {
      const tooltipAttrs = this.options.tooltipAttrs;
      if (tooltipAttrs) {
        if (typeof tooltipAttrs === "function") {
          return tooltipAttrs(this.finalValue, this.item, this);
        }
        return tooltipAttrs;
      }
      return {
        top: true,
      };
    },
    attrs() {
      const baseAttrs = {
        value: this.finalValue,
        item: this.item,
      };
      let decoratedAttrs: any = this.xOptions && this.xOptions.attrs;
      if (decoratedAttrs) {
        if (typeof decoratedAttrs === "function") {
          decoratedAttrs = decoratedAttrs(this.finalValue, this.item, this);
        }
      }
      return { ...baseAttrs, ...decoratedAttrs };
    },
    finalValue() {
      let value = this.value;
      if (this.options.subProp) {
        value = propertyGetter.get(this.item, this.options.subProp);
      }
      if (this.options.preTransform) {
        value = this.options.preTransform(value, this.item);
      }
      if (this.options.filter) {
        let args = [];
        if (this.options.filterParams) {
          args = this.options.filterParams;
        }
        const filter = Vue.filter(this.options.filter);
        value = filter(value, ...args);
      }
      if (this.options.transform) {
        value = this.options.transform(value, this.item);
      }

      return value;
    },
    icon() {
      return this.options.icon(this.finalValue, this.item);
    },
    label() {
      if (typeof this.options.label === "function") {
        return this.options.label(this.finalValue, this.item);
      }
      return this.finalValue;
    },
    labels() {
      if (typeof this.options.labels === "function") {
        return this.options.labels(this.finalValue, this.item);
      }
      if (typeof this.options.labelItems === "function") {
        if (this.finalValue) {
          const items = [];
          for (const i in this.finalValue) {
            items[i] = this.options.labelItems(this.finalValue[i], this.item);
          }
          return items;
        }
      }
      return this.finalValue;
    },
    href() {
      if (typeof this.options.href === "function") {
        return this.options.href(this.finalValue, this.item);
      }
      return this.options.href;
    },
    to() {
      if (typeof this.options.to === "function") {
        return this.options.to(this.finalValue, this.item);
      }
      return this.options.to;
    },
  },
  methods: {
    initialize() {},
  },
  extraOptions: {
    defaultClass: "display",
  },
});
